import { Input, Button, Space } from 'antd';
import { RefreshCcw } from 'lucide-react';

import { generatePassword } from 'utils/commonUtils';

export default function InputPassword({ value, onChange, ...props }) {
  const handleGenerate = () => {
    const password = generatePassword();

    onChange(password);
  };

  return (
    <Space.Compact style={{ width: '100%' }}>
      <Input.Password {...props} onChange={onChange} value={value} />
      <Button
        onClick={handleGenerate}
        icon={<RefreshCcw className='w-4 h-4' />}
      />
    </Space.Compact>
  );
}
