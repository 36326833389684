import { Button, Result } from 'antd';

import { cn } from 'utils/commonUtils';

export default function ErrorFallback({ status = 500 }) {
  return (
    <div
      className={cn(
        'w-full h-full flex items-center justify-center !bg-primary',
        {
          '!bg-transparent': status === 404,
          'min-h-screen': status === 500,
        }
      )}
    >
      <div
        className={cn('max-w-xl w-full', {
          'rounded-lg bg-white shadow-lg': status !== 404,
        })}
      >
        <Result
          status={status}
          title={status === 404 ? 'Oops!' : 'System Error'}
          subTitle={
            status === 404
              ? "We can't find that page."
              : 'Something went wrong! Please try again later.'
          }
          extra={
            <Button
              type='primary'
              onClick={() => window.location.assign(window.location.origin)}
            >
              Return Home
            </Button>
          }
        />
      </div>
    </div>
  );
}
