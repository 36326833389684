import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Input } from 'antd';
import Fuse from 'fuse.js';
import debounceFn from 'lodash/debounce';
import { Search } from 'lucide-react';

export function SearchTableInput({
  searchFunction = undefined,
  originalDataSource = [],
  setDataSource,
  inputProps,
  debounce = true,
  fuzzySearch = false,
  fuseProps,
  onSearched,
}) {
  const [query, setQuery] = useState('');
  const allData = useRef([]);
  const fuse = useRef();
  const dataSource = useMemo(
    () => originalDataSource ?? [],
    [originalDataSource]
  );

  const _fuseProps = useMemo(() => {
    return {
      keys: [],
      threshold: fuzzySearch ? 0.6 : 0.1,
      shouldSort: false,
      ...fuseProps,
    };
  }, [fuseProps, fuzzySearch]);

  useEffect(() => {
    if (!dataSource) {
      return;
    }

    allData.current = [...dataSource];
    fuse.current = new Fuse(dataSource, _fuseProps);
  }, [dataSource, _fuseProps]);

  const searchTable = useCallback((_dataSource, searchTerm = '') => {
    if (searchTerm === '' || !fuse || !fuse.current) {
      return allData.current ?? [];
    }

    const newResults = fuse.current.search(searchTerm).map((res) => res.item);
    return newResults;
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchTableDebounced = useCallback(
    debounceFn(
      (dataSource, searchTerm, searchFn) => {
        const results = searchFn?.(dataSource, searchTerm);
        setDataSource?.(results);
      },
      100,
      {
        leading: false,
        trailing: true,
      }
    ),
    []
  );

  const handleInputChange = useCallback(
    (e) => {
      const value = e.target.value;
      setQuery(value);

      if (debounce) {
        searchTableDebounced(dataSource, value, searchFunction ?? searchTable);
      } else {
        const results =
          searchFunction?.(dataSource, value) ?? searchTable(dataSource, value);
        setDataSource?.(results);
      }
      onSearched?.();
    },
    [
      dataSource,
      debounce,
      searchFunction,
      searchTable,
      searchTableDebounced,
      setDataSource,
      onSearched,
    ]
  );

  useEffect(() => {
    // If dataSource updates dynamically (for example, swr or react-query mutates) and the input box is not empty,
    // It should keep the new dataSource filtered if there is a value in input box
    // if (!dataSource || !query) {
    //   return;
    // }
    if (!dataSource) {
      return;
    }

    if (debounce) {
      searchTableDebounced(dataSource, query, searchFunction ?? searchTable);
    } else {
      const results =
        searchFunction?.(dataSource, query) ?? searchTable(dataSource, query);
      setDataSource?.(results);
    }
  }, [
    query,
    dataSource,
    searchTableDebounced,
    searchFunction,
    setDataSource,
    debounce,
    searchTable,
  ]);

  return (
    <div className='flex justify-end mb-8'>
      <div className='max-w-xs w-full max-sm:max-w-full'>
        <Input
          prefix={<Search className='w-4 h-4' />}
          placeholder='Search...'
          allowClear
          size='large'
          {...inputProps}
          value={query}
          onChange={handleInputChange}
        />
      </div>
    </div>
  );
}

export default SearchTableInput;
