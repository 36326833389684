import { useMutation } from '@tanstack/react-query';

import { axiosClient } from './axiosClient';

const authApi = {
  login: (data) => {
    return axiosClient.post('admin/login', data);
  },
  verifyToken: () => {
    return axiosClient.get('admin/verify-token');
  },
};

export const useLogin = () => {
  return useMutation(authApi.login);
};

export const useVerifyToken = () => {
  return useMutation(authApi.verifyToken);
};
