/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';

import { useAuthStore } from 'hooks/useAuthStore';
import { Loading } from 'components/ui';
import { checkPermission } from './checkPermission';

export default function Permission(props) {
  const {
    children,
    noAccess,
    entityOwnerId,
    roles = [],
    type = 'one-of',
    debug = false,
  } = props;
  const user = useAuthStore((state) => state.user);
  const [hasAccess, setHasAccess] = useState();

  useEffect(() => {
    if (!user) {
      setHasAccess(false);
      return;
    }

    const doesHaveAccess = checkPermission(user, roles, {
      type,
      entityOwnerId,
      debug,
    });

    setHasAccess(doesHaveAccess);
  }, [entityOwnerId, roles, type, user]);

  const renderNoAccess = () => {
    if (typeof noAccess === 'function') {
      return noAccess({
        user,
        hasAccess,
      });
    }
    return noAccess;
  };

  if (hasAccess === undefined) {
    return <Loading />;
  }

  return hasAccess ? children : renderNoAccess() || null;
}
