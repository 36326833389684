import { Link } from 'react-router-dom';
import { Breadcrumb, Card } from 'antd';
import { Home } from 'lucide-react';

import { ROUTES } from 'constants/routes';

export default function PageHeader({ title, breadcrumbItems = [] }) {
  return (
    <Card className='!mb-6'>
      <div className='flex items-center justify-between max-md:flex-col max-md:items-start'>
        <h3 className='text-2xl font-bold !text-primary mb-0'>{title}</h3>

        <Breadcrumb
          className='custom-breadcrumb'
          // separator='-'
          items={[
            {
              path: ROUTES.ROOT,
              title: <Home />,
            },
            ...breadcrumbItems,
          ]}
          itemRender={(route) => {
            if (route.path) {
              return (
                <Link to={route.path} className='text-base h-auto font-medium'>
                  {route.title}
                </Link>
              );
            }

            return (
              <span className='text-base h-auto font-medium'>
                {route.title}
              </span>
            );
          }}
        />
      </div>
    </Card>
  );
}
