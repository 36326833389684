import { Dropdown, Button } from 'antd';
import { MoreHorizontal } from 'lucide-react';

export default function Actions({ items = [], isLoading = false }) {
  return (
    <Dropdown menu={{ items }} trigger='click' placement='bottom'>
      <Button type='default' icon={<MoreHorizontal />} loading={isLoading} />
    </Dropdown>
  );
}
