import { useQuery } from '@tanstack/react-query';

import { axiosClient } from './axiosClient';

const appApi = {
  getLogo(data) {
    return axiosClient.post('admin/media-login', data);
  },
};

export const useGetLogo = ({ params, options } = {}) => {
  return useQuery({
    queryKey: ['admin', 'logo', params],
    queryFn: () => appApi.getLogo(params),
    ...options,
  });
};
