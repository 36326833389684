import { UserOutlined } from '@ant-design/icons';
import { Avatar, Dropdown } from 'antd';

import { useAuthStore } from 'hooks/useAuthStore';

export const UserAccount = () => {
  const user = useAuthStore((state) => state.user);
  const onLogout = useAuthStore((state) => state.onLogout);

  const items = [
    {
      key: 'logout',
      label: 'Sign Out',
      onClick: () => {
        onLogout?.();
      },
    },
  ];

  return (
    <Dropdown menu={{ items }}>
      <a
        onClick={(e) => e.preventDefault()}
        className='inline-flex items-center gap-1'
      >
        <Avatar
          size={28}
          style={{ backgroundColor: '#1677ff' }}
          icon={<UserOutlined />}
        />

        <span className='text-black/40'>{user?.username}</span>
      </a>
    </Dropdown>
  );
};
