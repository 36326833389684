import { Layout } from 'antd';
import { Navigate, Outlet } from 'react-router-dom';

import { ROUTES } from 'constants/routes';
import { STORAGE_KEY } from 'constants/common';
import { useAuthStore } from 'hooks/useAuthStore';
import { useCustomSearchParams } from 'hooks/useCustomSearchParams';

export const AuthLayout = () => {
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);
  const [search] = useCustomSearchParams();

  if (search.token) {
    localStorage.setItem(STORAGE_KEY.TOKEN, search.token);
  }

  if (isLoggedIn || localStorage.getItem(STORAGE_KEY.TOKEN) || search.token) {
    return <Navigate to={ROUTES.ROOT} />;
  }

  return (
    <Layout className='h-full !bg-white'>
      <Layout.Content className='flex-1'>
        <Outlet />
      </Layout.Content>
    </Layout>
  );
};
