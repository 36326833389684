import { Select as AntdSelect } from 'antd';

import { filterOption } from 'utils/commonUtils';

export default function Select(props) {
  return (
    <AntdSelect
      showSearch
      optionFilterProp='label'
      filterOption={filterOption}
      {...props}
    />
  );
}
