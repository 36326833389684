import { useIntl } from 'react-intl';
import { Button } from 'antd';
import { Plus } from 'lucide-react';

export default function AddNewBtn(props) {
  const intl = useIntl();

  return (
    <Button
      type='primary'
      icon={<Plus />}
      className='!inline-flex items-center'
      size='large'
      {...props}
    >
      {intl.formatMessage({ id: 'common.btn.addNew' })}
    </Button>
  );
}
