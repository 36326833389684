import axios from 'axios';
import dayjs from 'dayjs';

export const formatError = (error) => {
  if (axios.isAxiosError(error)) {
    const { code, response, message } = error;

    if (code === 'ECONNABORTED') {
      return {
        code,
        status: 408,
        message,
      };
    }

    return {
      code,
      status: response?.status,
      message,
      data: response?.data,
    };
  } else {
    return {
      message: error.message,
    };
  }
};

export const formatTime = (date, format = 'DD/MM/YYYY HH:mm:ss') => {
  const isValid = dayjs(date).isValid();

  if (isValid) {
    return dayjs(date).format(format);
  }

  return 'N/A';
};

export const formatKbToGb = (value) => {
  // return Math.round(((+value || 0) / 1000000) * 10) / 10;
  return ((+value || 0) / 1000000).toFixed(2);
};

export const formatInputNumber = (value) =>
  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const parseInputNumber = (value) => value.replace(/\$\s?|(,*)/g, '');

export const formatCurrency = (
  currency,
  locale = 'vi-VN',
  options = { currency: 'VND' }
) => {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    ...options,
  }).format(currency);
};

export const formatToNumber = (num) => {
  return +num || 0;
};

const BYTE_UNITS = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

export const formatMeasurementUnit = (
  number,
  { unitIndex = 1, decimals = 2 } = {}
) => {
  const k = 1000;
  const dm = decimals < 0 ? 0 : decimals;
  const UNITS = BYTE_UNITS.slice(unitIndex);

  if (!+number || +number <= 0) {
    const value = (0).toFixed(dm);
    const unit = UNITS[0];
    const string = `${value}${unit}`;

    return {
      value,
      unit,
      string,
    };
  }

  const exponent = Math.min(
    Math.floor(Math.log10(number) / 3),
    UNITS.length - 1
  );
  const normalizeExponent = exponent <= 0 ? 0 : exponent;
  number /= k ** normalizeExponent;
  const value = number.toFixed(dm);
  const unit = UNITS[normalizeExponent];
  const string = `${value}${unit}`;

  return {
    value,
    unit,
    string,
  };
};
