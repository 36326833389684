import {
  LayoutGrid,
  BarChart4,
  User,
  Settings,
  UserCog,
  Airplay,
  Boxes,
  FileSearch,
  LineChart,
  Globe,
  CircleDollarSign,
  BellRing,
} from 'lucide-react';

import { ROLE } from 'constants/roles';
import { ROUTES } from 'constants/routes';

export const getMenuItemsByRole = (role) => {
  switch (role) {
    case ROLE.SUPER_ADMIN: {
      return [
        {
          key: ROUTES.DASHBOARD,
          icon: <BarChart4 />,
        },

        {
          key: ROUTES.DATA_USAGE,
          icon: <LineChart />,
        },

        {
          key: 'user-management',
          icon: <User />,
          children: [
            {
              key: ROUTES.USERS,
            },
            {
              key: ROUTES.ACCOUNTS,
            },
            {
              key: ROUTES.LINKED_ACCOUNTS,
            },
            {
              key: ROUTES.GROUPS,
            },
            // {
            //   key: ROUTES.ROLES,
            // },
          ],
        },

        {
          key: 'provider-management',
          icon: <LayoutGrid />,
          children: [
            {
              key: ROUTES.PROVIDERS,
            },
            {
              key: ROUTES.PROVIDER_RULES,
            },
          ],
        },

        {
          key: 'dns-management',
          icon: <Globe />,
          children: [
            {
              key: ROUTES.DNS_ZONES,
            },
            {
              key: ROUTES.DNS_TEMPLATES,
            },
            {
              key: ROUTES.DNS_GROUPS,
            },
          ],
        },

        {
          key: 'infrastructure',
          icon: <Boxes />,
          children: [
            {
              key: ROUTES.OVERVIEW,
            },
            {
              key: ROUTES.EVENTS,
            },
            {
              key: ROUTES.SERVICES,
            },
            {
              key: ROUTES.DEVICES,
            },
            {
              key: ROUTES.ROUTING,
            },
          ],
        },

        {
          key: ROUTES.STORAGE_ACCOUNTS,
          icon: <UserCog />,
        },

        {
          key: ROUTES.LIVE_STREAMING,
          icon: <Airplay />,
        },

        {
          key: 'iam-management',
          icon: <Settings />,
          children: [
            {
              key: ROUTES.SUBADMIN_POLICIES,
            },
            {
              key: ROUTES.SUBADMIN_PERMISSIONS,
            },
            {
              type: 'divider',
              dashed: true,
            },
            {
              key: ROUTES.SYSTEM_POLICIES,
            },
            {
              key: ROUTES.PERMISSIONS,
            },
          ],
        },

        {
          key: 'billing-and-cost',
          icon: <CircleDollarSign />,
          children: [
            {
              key: ROUTES.ORDERS,
            },
            {
              key: ROUTES.PROMO_CODES,
            },
            {
              key: ROUTES.TRANSACTIONS,
            },

            {
              key: ROUTES.ITEM_PRICE,
            },
          ],
        },

        {
          key: ROUTES.ALARMS,
          icon: <BellRing />,
        },

        {
          key: ROUTES.LOGS,
          icon: <FileSearch />,
        },
      ];
    }

    default: {
      return [
        {
          key: ROUTES.DASHBOARD,
          icon: <BarChart4 />,
        },

        {
          key: ROUTES.DATA_USAGE,
          icon: <LineChart />,
        },

        {
          key: 'user-management',
          icon: <User />,
          children: [
            {
              key: ROUTES.USERS,
            },
            {
              key: ROUTES.ACCOUNTS,
            },
            {
              key: ROUTES.GROUPS,
            },
            // {
            //   key: ROUTES.ROLES,
            // },
          ],
        },

        // {
        //   key: 'dns-management',
        //   icon: <Globe />,
        //   children: [
        //     {
        //       key: ROUTES.DNS_GROUPS,
        //     },
        //   ],
        // },

        {
          key: 'infrastructure',
          icon: <Boxes />,
          children: [
            {
              key: ROUTES.OVERVIEW,
            },
            {
              key: ROUTES.EVENTS,
            },
            {
              key: ROUTES.SERVICES,
            },
            {
              key: ROUTES.DEVICES,
            },
            {
              key: ROUTES.ROUTING,
            },
          ],
        },

        {
          key: ROUTES.STORAGE_ACCOUNTS,
          icon: <UserCog />,
        },

        {
          key: ROUTES.LIVE_STREAMING,
          icon: <Airplay />,
        },

        {
          key: 'iam-management',
          icon: <Settings />,
          children: [
            {
              key: ROUTES.SUBADMIN_POLICIES,
            },
            // {
            //   key: ROUTES.SUBADMIN_PERMISSIONS,
            // },
            // {
            //   type: 'divider',
            //   dashed: true,
            // },
            // {
            //   key: ROUTES.SYSTEM_POLICIES,
            // },
            // {
            //   key: ROUTES.PERMISSIONS,
            // },
          ],
        },

        {
          key: 'billing-and-cost',
          icon: <CircleDollarSign />,
          children: [
            {
              key: ROUTES.ORDERS,
            },
            {
              key: ROUTES.PROMO_CODES,
            },
            {
              key: ROUTES.TRANSACTIONS,
            },

            {
              key: ROUTES.ITEM_PRICE,
            },
          ],
        },

        {
          key: ROUTES.ALARMS,
          icon: <BellRing />,
        },

        {
          key: ROUTES.LOGS,
          icon: <FileSearch />,
        },
      ];
    }
  }
};
