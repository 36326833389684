import axios from 'axios';
import queryString from 'query-string';
import Swal from 'sweetalert2';

import { formatError } from 'utils/formatUtils';
import { useAuthStore } from 'hooks/useAuthStore';
import { STORAGE_KEY } from 'constants/common';

export const axiosClient = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  timeout: 30000,
  paramsSerializer: {
    serialize: (params) =>
      queryString.stringify(params, {
        skipNull: true,
        skipEmptyString: true,
      }),
  },
});

axiosClient.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem(STORAGE_KEY.TOKEN);

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosClient.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    const formattedError = formatError(error);

    if (formattedError.status === 401 && formattedError.data?.code === 401) {
      useAuthStore.getState().onLogout();

      return Promise.reject(formattedError);
    }

    if (formattedError.status === 401 && error?.config?.method === 'get') {
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: formattedError.data?.message ?? 'Something went wrong',
        showConfirmButton: false,
        timer: 3000,
      });
    }

    return Promise.reject(formattedError);
  }
);
