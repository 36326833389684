import { useIntl } from 'react-intl';

import { STATUS } from 'constants/common';

export const MAPPING_STATUS = {
  [STATUS.PRE_INACTIVE]: {
    color: '#ffc700d9',
    bgColor: '#fff',
  },
  [STATUS.INACTIVE]: {
    color: '#F8285A',
    bgColor: '#FFEEF3',
  },
  [STATUS.ACTIVE]: {
    color: '#17C653',
    bgColor: '#DFFFEA',
  },
  [STATUS.PENDING]: {
    color: '#252F4A',
    bgColor: '#F9F9F9',
  },
  [STATUS.DEACTIVATION]: {
    color: '#3699FF',
    bgColor: '#E1F0FF',
  },
  [STATUS.DELETE]: {
    color: '#fff',
    bgColor: '#F8285A',
  },
};

export default function Status({ status }) {
  const intl = useIntl();
  const item = MAPPING_STATUS[status];

  if (!item) {
    return null;
  }

  return (
    <span
      className='inline-flex items-center justify-center font-semibold px-4 py-2 rounded-lg text-xs whitespace-nowrap'
      style={{
        color: item.color,
        backgroundColor: item.bgColor,
      }}
    >
      {intl.formatMessage({ id: `constants.status.${status}` })}
    </span>
  );
}
