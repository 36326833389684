export const STORAGE_KEY = {
  TOKEN: 'token',
};

export const APP_LOCALE = {
  VI: 'vi',
  EN: 'en',
};

export const LANGUAGES = [
  {
    name: 'Tiếng Việt',
    flag: '🇻🇳',
    key: APP_LOCALE.VI,
  },
  {
    name: 'English',
    flag: '🇻🇬',
    key: APP_LOCALE.EN,
  },
];

export const validateMessages = {
  [APP_LOCALE.EN]: {
    required: '${label} is required',
  },
  [APP_LOCALE.VI]: {
    required: '${label} bắt buộc',
  },
};

export const ROLE_PERMISSIONS = ['view', 'viewreport', 'modify', 'deleted'];

export const STATUS = {
  PRE_INACTIVE: 9,
  INACTIVE: 10,
  ACTIVE: 11,
  PENDING: 12,
  DEACTIVATION: 13,
  DELETE: 14,
};
export const SERVER_DATE_FORMAT = 'YYYYMMDD';

export const CLIENT_DATE_FORMAT = 'YYYY/MM/DD';

export const HEALTH_METRIC_STATUS = {
  GOOD: 1,
  WARNING: 2,
  ERROR: 3,
};

export const SERVER_TYPE = {
  EDGE: 'edge',
  CONTROLLER_EDGE: 'controller_edge',
  CACHE: 'cache',
  CONTROLLER_CACHE: 'controller_cache',
  LIVESTREAMING: 'livestreaming',
  STORAGE: 'storage',
  S3GW: 's3gw',
};

export const SERVER_PING_STATUS = {
  OK: 'ok',
  FAIL: 'fail',
};

export const DEFAULT_PAGINATION = {
  PAGE: 1,
  LIMIT: 10,
  MAX_LIMIT: 100,
};

export const DATE_TYPE = {
  '24H': '24h',
  TODAY: 'today',
  YESTERDAY: 'yesterday',
  THIS_MONTH: 'thismonth',
  LAST_MONTH: 'lastmonth',
  '7_DAYS': '7day',
  '30_DAYS': '30day',
  CUSTOM: 'custom',
};

export const DATE_TYPES = [
  // {
  //   label: '24h',
  //   value: DATE_TYPE['24H'],
  // },
  {
    label: 'Today',
    value: DATE_TYPE.TODAY,
  },
  {
    label: 'Yesterday',
    value: DATE_TYPE.YESTERDAY,
  },
  {
    label: 'This Month',
    value: DATE_TYPE.THIS_MONTH,
  },
  {
    label: 'Last Month',
    value: DATE_TYPE.LAST_MONTH,
  },
  {
    label: '7 Days',
    value: DATE_TYPE['7_DAYS'],
  },
  {
    label: '30 Days',
    value: DATE_TYPE['30_DAYS'],
  },
  {
    label: 'Custom',
    value: DATE_TYPE.CUSTOM,
  },
];

export const ALL_CDNS_KEY = 'all';

export const TOTAL_DATA_USAGE_FILTER = {
  DAY: 'day',
  MONTH: 'month',
};

export const REPORT_VIEW_TYPE = {
  OVERVIEW: 'overview',
  DETAILS: 'details',
};

export const REPORT_DATA_TYPE = {
  CURRENT: 'current',
  ALL: 'all',
};

export const ALL_DATA_KEY = 'all';

export const ACCOUNT_TYPE = {
  DEFAULT: 0,
  PREPAID: 1,
  LIMITED_DATE: 2, // POC
};

export const TRANSACTION_TYPE = {
  PROMOTION: 1,
  CUSTOM: 2,
  AUTO: 3,
};

export const REMAINING_VOLUME_TYPE = {
  GB: 'GB',
  PERCENT: 'Percent',
};

export const ALARM_CHANNEL_TYPE = {
  TELEGRAM: 'telegram',
  SMTP: 'smtp',
};

export const ALARM_TYPE = {
  DEFAULT: 0,
  CUSTOM: 1,
};
