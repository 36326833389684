import { APP_NAME } from 'constants/app';
import { cn } from 'utils/commonUtils';

import { useGetLogo } from 'api/appApi';

export default function Logo({ className, isDark = false, name = APP_NAME }) {
  const { data } = useGetLogo({
    params: {
      domain: window.location.hostname,
    },
  });

  return (
    <div className={cn('flex', className)}>
      {data ? (
        <div className={cn('h-10 inline-flex')}>
          <img src={data} alt='' className='max-h-full w-auto inline-block' />
        </div>
      ) : null}

      {name ? (
        <span
          className={cn(
            'text-sm inline-block font-semibold',
            isDark ? 'text-black' : 'text-white'
          )}
        >
          {name}
        </span>
      ) : null}
    </div>
  );
}
