import { clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';
import dayjs from 'dayjs';
import pickBy from 'lodash/pickBy';

import { DEFAULT_PAGINATION } from 'constants/common';

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

export const filterOption = (input, option) => {
  const isGroup = Array.isArray(option.options);

  if (isGroup) {
    return false;
  }

  return (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
};

export const calcCurrentPage = ({
  page = 1,
  limit = 10,
  total = 0,
  num = 1,
}) => {
  const calculatedPage = Math.ceil((total - num) / limit) || 1;

  return calculatedPage >= page ? page : calculatedPage;
};

export function generateRandomColor() {
  var letters = '0123456789ABCDEF';
  var color = '#';
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export const checkExpiryDate = (date, currentDate = new Date()) => {
  return dayjs(currentDate).isAfter(dayjs(date));
};

export const removeFalsyKeys = (object, optionalFunc) => {
  return pickBy(object, optionalFunc);
};

export const generatePassword = (length = 12) => {
  const alpha = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const numbers = '0123456789';
  const symbols = '!@#$%^&*_-+=';
  const characters = alpha + numbers + symbols;

  let password = '';
  for (let i = 0; i < length; i++) {
    password += characters.charAt(
      Math.floor(Math.random() * characters.length)
    );
  }
  return password;
};

export const generateIndexInTable = ({
  index,
  currentPage = DEFAULT_PAGINATION.PAGE,
  limit = DEFAULT_PAGINATION.LIMIT,
} = {}) => {
  return (currentPage - 1) * limit + index + 1;
};
