import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

export default function Loading() {
  return (
    <div className='flex items-center justify-center w-full h-full'>
      <Spin indicator={<LoadingOutlined style={{ fontSize: 36 }} spin />} />
    </div>
  );
}
