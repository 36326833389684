import enUS from 'antd/locale/en_US';
import viVN from 'antd/locale/vi_VN';

import enUSMessages from './messages/en-US.json';
import viVNMessages from './messages/vi-VN.json';
import { APP_LOCALE } from 'constants/common';

export const appLocale = {
  [APP_LOCALE.EN]: {
    antd: enUS,
    messages: enUSMessages,
  },
  [APP_LOCALE.VI]: {
    antd: viVN,
    messages: viVNMessages,
  },
};
