import { lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { ROLE } from 'constants/roles';
import { ROUTES, ROUTE_ACTIONS } from 'constants/routes';
import PrivateRoute from 'components/common/PrivateRoute';
import { Permission } from 'components/common';
import { AuthLayout } from 'layouts/AuthLayout';
import { DashboardLayout } from 'layouts/DashboardLayout';

const Login = lazy(() => import('views/auth/Login'));

const NotFound = lazy(() => import('views/common/ErrorFallback'));

const InvoicePage = lazy(() => import('modules/invoice/pages/InvoicePage'));

const Groups = lazy(() => import('views/groups/Groups'));
const ViewAccountsByGroup = lazy(() =>
  import('views/groups/ViewAccountsByGroup')
);

const Providers = lazy(() => import('views/providers/Providers'));

const ProviderRules = lazy(() => import('views/provider-rules/ProviderRules'));

// const Roles = lazy(() => import('views/roles/Roles'));

const SubAdminPermissions = lazy(() =>
  import('views/sub-admin-permissions/SubAdminPermissions')
);

const SubAdminPolicies = lazy(() =>
  import('views/sub-admin-policies/SubAdminPolicies')
);
const AddSubAdminPolicy = lazy(() =>
  import('views/sub-admin-policies/AddSubAdminPolicy')
);
const ViewSubAdminPolicy = lazy(() =>
  import('views/sub-admin-policies/ViewSubAdminPolicy')
);

const AccountPermissions = lazy(() =>
  import('views/account-permissions/AccountPermissions')
);

const AccountPolicies = lazy(() =>
  import('views/account-policies/AccountPolicies')
);
const AddAccountPolicy = lazy(() =>
  import('views/account-policies/AddAccountPolicy')
);
const ViewAccountPolicy = lazy(() =>
  import('views/account-policies/ViewAccountPolicy')
);

// const PdnsDefaultList = lazy(() =>
//   import('views/pdns-default/PdnsDefaultList')
// );

const Users = lazy(() => import('views/users/Users'));
const SubUsers = lazy(() => import('views/users/SubUsers'));
const ViewUserPermissions = lazy(() =>
  import('views/users/ViewUserPermissions')
);

const Accounts = lazy(() => import('views/accounts/Accounts'));
const SubAccounts = lazy(() => import('views/accounts/SubAccounts'));

const PromoCodes = lazy(() => import('views/promo-codes/PromoCodes'));

const Orders = lazy(() => import('views/orders/Orders'));
const AddOrder = lazy(() => import('views/orders/AddOrder'));
const ViewOrder = lazy(() => import('views/orders/ViewOrder'));

const ServerInfo = lazy(() => import('views/server-info/ServerInfo'));
const ViewServerInfoMonitoring = lazy(() =>
  import('views/server-info/ViewServerInfoMonitoring')
);
const ViewServerInfoStatisticOverview = lazy(() =>
  import('views/server-info/ViewServerInfoStatisticOverview')
);
const Routing = lazy(() => import('views/routing/Routing'));
const Events = lazy(() => import('views/events/Events'));
const Services = lazy(() => import('views/services/Services'));

const StorageAccounts = lazy(() =>
  import('views/storage-accounts/StorageAccounts')
);

const LiveStreaming = lazy(() => import('views/live-streaming/LiveStreaming'));

const Logs = lazy(() => import('views/logs/Logs'));

const DataUsage = lazy(() => import('views/data-usage/DataUsage'));

const LinkedAccounts = lazy(() =>
  import('views/linked-accounts/LinkedAccounts')
);

const Dashboard = lazy(() => import('views/dashboard/Dashboard'));

// const DNSAccounts = lazy(() => import('views/dns-accounts/DNSAccounts'));

const DNSZoneList = lazy(() => import('views/dns-zones/DNSZoneList'));
const ViewDNSZone = lazy(() => import('views/dns-zones/ViewDNSZone'));

const DNSTemplateList = lazy(() =>
  import('views/dns-templates/DNSTemplateList')
);
const AddDNSTemplate = lazy(() => import('views/dns-templates/AddDNSTemplate'));
const ViewDNSTemplate = lazy(() =>
  import('views/dns-templates/ViewDNSTemplate')
);

const DNSGroups = lazy(() => import('views/dns-groups/DNSGroups'));
const AddDNSGroup = lazy(() => import('views/dns-groups/AddDNSGroup'));
const ViewDNSGroup = lazy(() => import('views/dns-groups/ViewDNSGroup'));

const Transactions = lazy(() => import('views/transactions/Transactions'));

const ItemPriceList = lazy(() => import('views/item-price/ItemPriceList'));

const Alarms = lazy(() => import('views/alarms/Alarms'));

export const AppRoutes = () => {
  return (
    <Routes>
      <Route
        path={ROUTES.ROOT}
        element={
          <PrivateRoute>
            <DashboardLayout />
          </PrivateRoute>
        }
      >
        <Route index element={<Navigate to={ROUTES.DASHBOARD} />} />

        <Route path={ROUTES.DASHBOARD} element={<Dashboard />} />

        {/* GROUPS */}
        <Route path={ROUTES.GROUPS}>
          <Route index element={<Groups />} />

          <Route
            path={`:${ROUTE_ACTIONS.DETAILS}/${ROUTES.GROUP_DETAIL}`}
            element={<ViewAccountsByGroup />}
          />
        </Route>

        {/* PROVIDERS */}
        <Route
          path={ROUTES.PROVIDERS}
          element={
            <Permission
              roles={[ROLE.SUPER_ADMIN]}
              noAccess={<NotFound status={404} />}
            >
              <Providers />
            </Permission>
          }
        />

        {/* PROVIDER-RULES */}
        <Route
          path={ROUTES.PROVIDER_RULES}
          element={
            <Permission
              roles={[ROLE.SUPER_ADMIN]}
              noAccess={<NotFound status={404} />}
            >
              <ProviderRules />
            </Permission>
          }
        />

        {/* ROLES */}
        {/* <Route path={ROUTES.ROLES} element={<Roles />} /> */}

        {/* ACCOUNTS */}
        <Route path={ROUTES.ACCOUNTS}>
          <Route index element={<Accounts />} />

          <Route
            path={`:${ROUTE_ACTIONS.DETAILS}/${ROUTES.SUB_ACCOUNTS}`}
            element={<SubAccounts />}
          />
        </Route>

        {/* LINKED ACCOUNTS */}
        <Route path={ROUTES.LINKED_ACCOUNTS}>
          <Route
            index
            element={
              <Permission
                roles={[ROLE.SUPER_ADMIN]}
                noAccess={<NotFound status={404} />}
              >
                <LinkedAccounts />
              </Permission>
            }
          />
        </Route>

        {/* USERS */}
        <Route path={ROUTES.USERS}>
          <Route index element={<Users />} />

          <Route
            path={`:${ROUTE_ACTIONS.DETAILS}/${ROUTES.SUB_USERS}`}
            element={<SubUsers />}
          />

          <Route
            path={`:${ROUTE_ACTIONS.DETAILS}/${ROUTES.PERMISSIONS}`}
            element={<ViewUserPermissions />}
          />
        </Route>

        {/* PROMO CODES */}
        <Route path={ROUTES.PROMO_CODES} element={<PromoCodes />} />

        {/* ORDERS */}
        <Route path={ROUTES.ORDERS}>
          <Route index element={<Orders />} />

          <Route path={ROUTE_ACTIONS.ADD} element={<AddOrder />} />

          <Route path={`:${ROUTE_ACTIONS.DETAILS}`} element={<ViewOrder />} />
        </Route>

        {/* INVOICES */}
        <Route path={ROUTES.INVOICES} element={<InvoicePage />} />

        {/* ACCOUNT PERMISSIONS */}
        <Route
          path={ROUTES.PERMISSIONS}
          element={
            <Permission
              roles={[ROLE.SUPER_ADMIN]}
              noAccess={<NotFound status={404} />}
            >
              <AccountPermissions />
            </Permission>
          }
        />

        {/* ACCOUNT POLICIES */}
        <Route path={ROUTES.SYSTEM_POLICIES}>
          <Route
            index
            element={
              <Permission
                roles={[ROLE.SUPER_ADMIN]}
                noAccess={<NotFound status={404} />}
              >
                <AccountPolicies />
              </Permission>
            }
          />

          <Route
            path={ROUTE_ACTIONS.ADD}
            element={
              <Permission
                roles={[ROLE.SUPER_ADMIN]}
                noAccess={<NotFound status={404} />}
              >
                <AddAccountPolicy />
              </Permission>
            }
          />

          <Route
            path={`:${ROUTE_ACTIONS.DETAILS}`}
            element={
              <Permission
                roles={[ROLE.SUPER_ADMIN]}
                noAccess={<NotFound status={404} />}
              >
                <ViewAccountPolicy />
              </Permission>
            }
          />
        </Route>

        {/* USER PERMISSIONS */}
        <Route
          path={ROUTES.SUBADMIN_PERMISSIONS}
          element={
            <Permission
              roles={[ROLE.SUPER_ADMIN]}
              noAccess={<NotFound status={404} />}
            >
              <SubAdminPermissions />
            </Permission>
          }
        />

        {/* USER POLICIES */}
        <Route path={ROUTES.SUBADMIN_POLICIES}>
          <Route index element={<SubAdminPolicies />} />

          <Route path={ROUTE_ACTIONS.ADD} element={<AddSubAdminPolicy />} />

          <Route
            path={`:${ROUTE_ACTIONS.DETAILS}`}
            element={<ViewSubAdminPolicy />}
          />
        </Route>

        {/* DEFAULT DNS */}
        {/* <Route path={ROUTES.DEFAULT_DNS} element={<PdnsDefaultList />} /> */}

        {/* SERVER INFO */}
        {/* <Route path={ROUTES.SERVER_INFO}>
          <Route index element={<ServerInfo />} />
          <Route
            path={`:${ROUTE_ACTIONS.DETAILS}`}
            element={<ViewServerInfoMonitoring />}
          />
        </Route> */}

        {/* STORAGE ACCOUNTS */}
        <Route path={ROUTES.STORAGE_ACCOUNTS} element={<StorageAccounts />} />

        {/* LIVE STREAMING */}
        <Route path={ROUTES.LIVE_STREAMING} element={<LiveStreaming />} />

        {/* INFRASTRUCTURE */}
        <Route path={ROUTES.DEVICES}>
          <Route index element={<ServerInfo />} />
          <Route
            path={`:${ROUTE_ACTIONS.DETAILS}`}
            element={<ViewServerInfoMonitoring />}
          />
        </Route>

        <Route path={ROUTES.OVERVIEW}>
          <Route index element={<ViewServerInfoStatisticOverview />} />
        </Route>

        <Route path={ROUTES.ROUTING}>
          <Route index element={<Routing />} />
        </Route>

        <Route path={ROUTES.EVENTS}>
          <Route index element={<Events />} />
        </Route>

        <Route path={ROUTES.SERVICES}>
          <Route index element={<Services />} />
        </Route>

        <Route path={ROUTES.LOGS}>
          <Route index element={<Logs />} />
        </Route>

        <Route path={ROUTES.DATA_USAGE} element={<DataUsage />} />

        <Route path={ROUTES.DNS_ZONES}>
          <Route
            index
            element={
              <Permission
                roles={[ROLE.SUPER_ADMIN]}
                noAccess={<NotFound status={404} />}
              >
                <DNSZoneList />
              </Permission>
            }
          />

          <Route
            path={`:${ROUTE_ACTIONS.DETAILS}`}
            element={
              <Permission
                roles={[ROLE.SUPER_ADMIN]}
                noAccess={<NotFound status={404} />}
              >
                <ViewDNSZone />
              </Permission>
            }
          />
        </Route>

        <Route path={ROUTES.DNS_TEMPLATES}>
          <Route
            index
            element={
              <Permission
                roles={[ROLE.SUPER_ADMIN]}
                noAccess={<NotFound status={404} />}
              >
                <DNSTemplateList />
              </Permission>
            }
          />

          <Route
            path={ROUTE_ACTIONS.ADD}
            element={
              <Permission
                roles={[ROLE.SUPER_ADMIN]}
                noAccess={<NotFound status={404} />}
              >
                <AddDNSTemplate />
              </Permission>
            }
          />

          <Route
            path={`:${ROUTE_ACTIONS.DETAILS}`}
            element={
              <Permission
                roles={[ROLE.SUPER_ADMIN]}
                noAccess={<NotFound status={404} />}
              >
                <ViewDNSTemplate />
              </Permission>
            }
          />
        </Route>

        <Route path={ROUTES.DNS_GROUPS}>
          <Route
            index
            element={
              <Permission
                roles={[ROLE.SUPER_ADMIN]}
                noAccess={<NotFound status={404} />}
              >
                <DNSGroups />
              </Permission>
            }
          />

          <Route
            path={ROUTE_ACTIONS.ADD}
            element={
              <Permission
                roles={[ROLE.SUPER_ADMIN]}
                noAccess={<NotFound status={404} />}
              >
                <AddDNSGroup />
              </Permission>
            }
          />

          <Route
            path={`:${ROUTE_ACTIONS.DETAILS}`}
            element={
              <Permission
                roles={[ROLE.SUPER_ADMIN]}
                noAccess={<NotFound status={404} />}
              >
                <ViewDNSGroup />
              </Permission>
            }
          />
        </Route>

        {/*
        <Route path={ROUTES.DNS_ACCOUNTS}>
          <Route index element={<DNSAccounts />} />
        </Route> */}

        <Route path={ROUTES.TRANSACTIONS}>
          <Route index element={<Transactions />} />
        </Route>

        <Route path={ROUTES.ITEM_PRICE}>
          <Route index element={<ItemPriceList />} />
        </Route>

        <Route path={ROUTES.ALARMS}>
          <Route index element={<Alarms />} />
        </Route>

        <Route path='*' element={<NotFound status={404} />} />
      </Route>

      <Route element={<AuthLayout />}>
        <Route path={ROUTES.LOGIN} element={<Login />} />
      </Route>

      <Route path='*' element={<NotFound status={404} />} />
    </Routes>
  );
};
