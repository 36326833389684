import React from 'react';
import { StyleProvider } from '@ant-design/cssinjs';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { App, ConfigProvider } from 'antd';
import { flatten } from 'flat';
import { ErrorBoundary } from 'react-error-boundary';
import { IntlProvider } from 'react-intl';
import { BrowserRouter } from 'react-router-dom';

import Loading from 'components/ui/Loading';
import { validateMessages } from 'constants/common';
import { useAppStore } from 'hooks/useAppStore';
import { queryClient } from 'lib/react-query';
import { appLocale } from 'translations';
import ErrorFallback from 'views/common/ErrorFallback';

export const AppProvider = ({ children }) => {
  const locale = useAppStore((state) => state.locale);

  return (
    <React.Suspense fallback={<Loading />}>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <QueryClientProvider client={queryClient}>
          <ConfigProvider
            locale={appLocale[locale].antd}
            theme={{
              token: {
                colorPrimary: '#1B84FF',
                colorPrimaryHover: '#056EE9',
                colorLinkHover: '#056EE9',
                fontFamily: "'Inter', sans-serif",
              },
              components: {
                Table: {
                  headerColor: '#555',
                  headerBg: 'transparent',
                  borderColor: '#e2e8f0',
                  headerBorderRadius: 0,
                },
                Notification: {
                  fontFamily: 'unset',
                },
                Layout: {
                  headerHeight: 48,
                  headerBg: '#fff',
                },
                Menu: {
                  colorSplit: 'rgba(54, 78, 101, .5)',
                },
                Collapse: {
                  headerBg: '#f1f5f9',
                },
                Breadcrumb: {
                  lastItemColor: '#1B84FF',
                },
              },
            }}
            componentSize='middle'
            form={{
              validateMessages: validateMessages[locale],
            }}
          >
            <App className='h-full' notification={{ placement: 'topRight' }}>
              <StyleProvider hashPriority='high'>
                <IntlProvider
                  locale={locale}
                  messages={flatten(appLocale[locale].messages)}
                >
                  <BrowserRouter>{children}</BrowserRouter>
                </IntlProvider>
              </StyleProvider>
            </App>
          </ConfigProvider>

          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </ErrorBoundary>
    </React.Suspense>
  );
};
