import { useCallback, useEffect, useMemo, useState } from 'react';
import { Menu } from 'antd';
import _ from 'lodash';
import { useIntl } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';

import { getMenuItemsByRole } from './menu-items';
import { useAuthStore } from 'hooks/useAuthStore';

export const DashboardMenu = (props) => {
  const location = useLocation();
  const intl = useIntl();
  const user = useAuthStore((state) => state.user);

  const normalizeMenuItems = useCallback((items) => {
    return _.map(items, (item) => {
      if (item.type === 'group' || item.type === 'divider') {
        return item;
      }

      if (item.key) {
        const label = intl.formatMessage({ id: `sidebar.${item.key}` });

        if (!item.children) {
          item.label = <Link to={`/${item.key}`}>{label}</Link>;
        } else {
          item.label = label;
          item.children = normalizeMenuItems(item.children);
        }
      }

      return item;
    });
  }, []);

  const items = useMemo(
    () => normalizeMenuItems(getMenuItemsByRole(user?.username)),
    [normalizeMenuItems, user?.username]
  );

  const mappingItems = useMemo(() => {
    return _.reduce(
      items,
      (result, item) => {
        if (item.type === 'group' || item.type === 'divider') {
          return result;
        }

        if (!item.children) {
          result[item.key] = {
            openKeys: [],
            selectedKeys: [item.key],
          };
        }

        _.forEach(item.children, (child) => {
          result[child.key] = {
            openKeys: [item.key],
            selectedKeys: [child.key],
          };
        });

        return result;
      },
      {}
    );
  }, [items]);

  const mainPathname = location.pathname.split('/')[1];

  const [openKeys, setOpenKeys] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);

  useEffect(() => {
    setOpenKeys(mappingItems[mainPathname]?.openKeys ?? []);
    setSelectedKeys(mappingItems[mainPathname]?.selectedKeys ?? []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainPathname]);

  return (
    <Menu
      theme='dark'
      mode='inline'
      selectedKeys={selectedKeys}
      openKeys={openKeys}
      items={items}
      onOpenChange={setOpenKeys}
      onClick={({ key }) => setSelectedKeys([key])}
      {...props}
    />
  );
};
