import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

import { APP_LOCALE } from 'constants/common';
import { env } from 'config/env';

export const useAppStore = create(
  devtools(
    persist(
      (set) => ({
        locale: APP_LOCALE.EN,
        changeLocale: (locale) => {
          set({ locale });
        },
      }),
      {
        name: 'app-storage',
      }
    ),
    {
      enabled: env.IS_DEV,
    }
  )
);
