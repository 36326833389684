import { Typography } from 'antd';

export default function ExpiredDate({ isExpired = false, ...props }) {
  return (
    <Typography.Text
      type={isExpired ? 'danger' : undefined}
      strong={isExpired}
      className='text-nowrap'
      {...props}
    />
  );
}
