import { useIntl } from 'react-intl';

import { Tag } from 'components/ui';

export default function PolicyType({ type }) {
  const intl = useIntl();
  const isSystem = !type;

  return (
    <Tag type={isSystem ? 'destructive' : 'secondary'}>
      {intl.formatMessage({
        id: `constants.policyType.${isSystem ? 'system' : 'custom'}`,
      })}
    </Tag>
  );
}
