import { useState, forwardRef } from 'react';
import { Spin } from 'antd';

export default forwardRef(function Iframe(props, ref) {
  const [isMounted, setIsMounted] = useState(false);

  return (
    <Spin spinning={!isMounted} style={{ background: '#FFF' }}>
      <iframe
        frameBorder='0'
        width='100%'
        {...props}
        onLoad={() => setIsMounted(true)}
        ref={ref}
      />
    </Spin>
  );
});
