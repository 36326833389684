import { useIntl } from 'react-intl';

import { Tag } from 'components/ui';

export default function TransactionType({ type }) {
  const intl = useIntl();

  return (
    <Tag type='secondary'>
      {intl.formatMessage({ id: `constants.transactionType.${type}` })}
    </Tag>
  );
}
