import { Flex, Layout, Button, Grid } from 'antd';
import { Menu } from 'lucide-react';

// import { I18nWidget } from 'components/common';
import { Logo } from 'components/ui';
import { UserAccount } from './UserAccount';

export const DashboardHeader = ({ onCollapse }) => {
  const screens = Grid.useBreakpoint();

  return (
    <>
      <Layout.Header className='!bg-transparent' />

      <Layout.Header className='fixed z-10 top-0 left-0 right-0 !flex items-center justify-between !px-4 sm:!px-7 border-solid border-t-0 border-l-0 border-r-0 border-b border-b-[#0505050f]'>
        <div className='flex items-center'>
          {screens.lg ? (
            <Logo isDark />
          ) : (
            <Button
              icon={<Menu />}
              className='flex-shrink-0'
              onClick={onCollapse}
            />
          )}
        </div>

        <Flex align='center' gap={16}>
          {/* <I18nWidget showIcon /> */}

          <UserAccount />
        </Flex>
      </Layout.Header>
    </>
  );
};
