import { Tag as AntdTag } from 'antd';

import { cn } from 'utils/commonUtils';

const tagTypes = {
  secondary: '!bg-[#f4f4f5] !text-[#18181b] border-[#e5e7eb]',
  outline: '!bg-transparent !text-[#09090b] !border-[##e4e4e7]',
  destructive: '!bg-[#dc2626] !text-white !border-[#dc2626]',
  default: '!bg-[#18181b] !text-white !border-[#18181b]',
};

export default function Tag({ type, className, ...props }) {
  return (
    <AntdTag
      {...props}
      className={cn(
        '!font-semibold me-0',
        tagTypes[type] ?? tagTypes['default'],
        className
      )}
    />
  );
}
