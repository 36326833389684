/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';

import { ROUTES } from 'constants/routes';
import { useVerifyToken } from 'api/authApi';
import { useAuthStore } from 'hooks/useAuthStore';
import { STORAGE_KEY } from 'constants/common';
import Loading from 'components/ui/Loading';

export default function PrivateRoute({ children }) {
  const verifyTokenMutation = useVerifyToken();
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);
  const onLoginSuccess = useAuthStore((state) => state.onLoginSuccess);
  const onLogout = useAuthStore((state) => state.onLogout);
  const accessToken = localStorage.getItem(STORAGE_KEY.TOKEN);
  const [isChecking, setIsChecking] = useState(true);

  useEffect(() => {
    if (accessToken && !isLoggedIn) {
      (async () => {
        try {
          await verifyTokenMutation.mutateAsync();

          onLoginSuccess(accessToken);
        } catch (error) {
          onLogout();
        } finally {
          setIsChecking(false);
        }
      })();
    } else {
      setIsChecking(false);
    }
  }, [accessToken, isLoggedIn]);

  if (isChecking) {
    return <Loading />;
  }

  return isLoggedIn ? children : <Navigate to={ROUTES.LOGIN} />;
}
