import { useEffect, useMemo, useState } from 'react';
import { Table } from 'antd';
import map from 'lodash/map';
import { useIntl } from 'react-intl';

import SearchTableInput from './SearchTableInput';

export default function TableWrapper({
  dataSource,
  renderActions,
  pagination,
  searchableProps,
  searchable = false,
  ...props
}) {
  const intl = useIntl();
  const isSearchable = useMemo(
    () => searchable || searchableProps,
    [searchable, searchableProps]
  );
  const [searchDataSource, setSearchDataSource] = useState(() => dataSource);

  useEffect(() => {
    if (isSearchable) return;

    setSearchDataSource(dataSource);
  }, [dataSource, isSearchable]);

  const mappedDataSource = useMemo(() => {
    return map(searchDataSource, (item, index) => ({
      ...item,
      index,
    }));
  }, [searchDataSource]);

  return (
    <>
      {!!renderActions && (
        <div className='flex items-center w-full justify-end mb-6 space-x-4'>
          {renderActions}
        </div>
      )}

      {searchable ? (
        <SearchTableInput
          originalDataSource={dataSource}
          setDataSource={setSearchDataSource}
          {...searchableProps}
        />
      ) : null}

      <Table
        pagination={
          pagination === false
            ? pagination
            : {
                position: ['bottomRight'],
                showQuickJumper: false,
                showTotal: (total) =>
                  total
                    ? intl.formatMessage(
                        { id: 'common.field.totalOfItems' },
                        { total }
                      )
                    : null,
                locale: { page: '' },
                ...pagination,
              }
        }
        bordered={false}
        rowClassName={(_record, index) =>
          index % 2 === 0 ? 'table-row-light' : 'table-row-dark'
        }
        size='small'
        // scroll={{ x: 'max-content' }}
        tableLayout='auto'
        scroll={{ x: '100%' }}
        {...props}
        dataSource={mappedDataSource}
      />
    </>
  );
}
