import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { jwtDecode } from 'jwt-decode';
import { queryClient } from 'lib/react-query';

import { STORAGE_KEY } from 'constants/common';
import { env } from 'config/env';

export const useAuthStore = create(
  devtools(
    (set) => ({
      user: null,
      isLoggedIn: false,
      onLoginSuccess: (token) => {
        localStorage.setItem(STORAGE_KEY.TOKEN, token);

        const user = jwtDecode(token);

        set({ isLoggedIn: true, user });
      },
      onLogout: () => {
        localStorage.removeItem(STORAGE_KEY.TOKEN);
        queryClient.clear();

        set({ isLoggedIn: false, user: null });
      },
    }),
    {
      name: 'AuthStore',
      enabled: env.IS_DEV,
    }
  )
);
